import { getFilterRenderTypeByGroupKey } from '../../../components/vehicle/vehicle-product-list/vehicle-product-list-filter/filter.helpers';
import {
    AvailabilityFilterKey,
    OrderAvailabilityKey,
    InStockAvailabilityKey,
    UsedAvailabilityKey,
    BrandFilterKey,
    FirstYearRegFilterKey,
    BusinessPaymentTypeFilterKey,
    PrivatePaymentTypeFilterKey,
} from '../../../constants/site-consts';
import { formatPrice } from '../../../utils/helpers';
import { ProductListApiResponse, ProductListFacet } from '../../api/models/hessel-api/hire';
import { GlobalProductListSettings, OptionWithIcon } from '../../api/models/umbraco';
import { BaseFilterSetup } from '../../api/models/umbraco/content-spot';
import { FacetGroup, FacetOption } from '../../view-models/vehicle';

/**
 * Important note:
 * We don't want model facets to be displayed as main facet if brand is available. This is because
 * when a users selects a brand, models corresponding to that brand are loaded as sub-facets.
 * @param apiResponse
 * @param filterLabels
 * @returns {FacetGroup[]}
 */
export const createFacetArray = (
    apiResponse: ProductListApiResponse,
    globalPlpSettings: GlobalProductListSettings,
    baseFilter: BaseFilterSetup,
    allowModelOption = false
): FacetGroup[] => {
    const facets: FacetGroup[] = [];
    const brandFacetAvailable = Object.keys(apiResponse.facets).includes('brand.keyword');
    const isCompanyCar = baseFilter.alias === 'carBaseFilter' && baseFilter.showAsCompanyCars;

    Object.keys(apiResponse.facets).forEach((key) => {
        if (brandFacetAvailable && key === 'modelTitle.keyword' && !allowModelOption) {
            return;
        }
        if (key === 'modelTitleName.keyword' || key === 'brandModelTitle.keyword') {
            return;
        }
        const selectedFacet = apiResponse.facets[key].find((x) => x);
        const productListFacet: ProductListFacet[] =
            key === AvailabilityFilterKey
                ? [
                      ...baseFilter.availabilities.map((x) => {
                          return {
                              count: 0,
                              key: x,
                              selected: x.toString() === selectedFacet?.key,
                          };
                      }),
                  ]
                : apiResponse.facets[key];
        const facetLabel = globalPlpSettings.filterGroupLabels.find((x) => x.filterGroupKey === key);
        facets.push(
            mapApiFacetGroupToViewModelFacetGroup(
                key,
                productListFacet,
                facetLabel?.filterGroupLabelText ?? key,
                facetLabel?.optionIcons,
                isCompanyCar
            )
        );
    });
    return facets;
};

export const mapApiFacetGroupToViewModelFacetGroup = (
    facetKey: string,
    facets: ProductListFacet[],
    label: string,
    optionIcons?: OptionWithIcon[],
    isCompanyCar?: boolean
): FacetGroup => {
    let facetsSorted = facets;

    if (facetKey === AvailabilityFilterKey) {
        const sortedAvailability = [OrderAvailabilityKey, InStockAvailabilityKey, UsedAvailabilityKey];
        facetsSorted = facets.sort((a, b) => {
            return sortedAvailability.indexOf(a.key) - (sortedAvailability.indexOf(b.key) === -1 ? 99 : sortedAvailability.indexOf(b.key));
        });
    } else if (facetKey === BrandFilterKey) {
        const hesselBrands = ['Mercedes-Benz', 'Renault', 'Dacia', 'Ford', 'XPENG'];
        const hesselBrandFacets = facets.filter((x) => hesselBrands.includes(x.key));
        const nonHesselBrandFacets = facets.filter((x) => !hesselBrands.includes(x.key));
        facetsSorted = [...hesselBrandFacets, ...nonHesselBrandFacets];
    }
    return {
        key: facetKey,
        label,
        facetOptions: facets
            ? [
                  ...facetsSorted.map((x) =>
                      mapApiFacetToViewmodelFacetOption(
                          x,
                          facetKey,
                          optionIcons?.find((y) => y.optionKey === x.key),
                          isCompanyCar
                      )
                  ),
              ]
            : [],
    };
};
export const mapApiFacetToViewmodelFacetOption = (
    apiFacet: ProductListFacet,
    groupKey: string,
    optionIcon?: OptionWithIcon,
    isCompanyCar?: boolean
): FacetOption => {
    const isIntervalValue = getFilterRenderTypeByGroupKey(groupKey) === 'Dropdown interval';
    const filterQueryValue = isIntervalValue ? apiFacet.key : getFilterQueryValue(apiFacet.key, groupKey);
    return {
        count: apiFacet.count,
        key: getFacetKey(apiFacet, groupKey, isCompanyCar),
        selected: apiFacet.selected,
        icon: optionIcon?.optionIcon ?? null,
        filterQueryValue,
        subFacets: apiFacet.subFacets ? [...apiFacet.subFacets.map((x) => mapApiFacetToViewmodelFacetOption(x, x.facetName))] : [],
    };
};

export const getFilterQueryValue = (optionKey: string, filterKey: string): string => {
    return `${filterKey.replace('.keyword', '')}__${optionKey}`;
};

const getFacetKey = (apiFacet: ProductListFacet, groupKey: string, isCompanyCar?: boolean): string => {
    if (groupKey.startsWith(PrivatePaymentTypeFilterKey)) {
        if (apiFacet.key === 'Buy') return 'Køb';
    }
    if (groupKey.startsWith(BusinessPaymentTypeFilterKey)) {
        if (apiFacet.key === 'Buy') return isCompanyCar ? 'Beskatning' : 'Køb';
    }
    return isNaN(Number(apiFacet.key)) || groupKey === FirstYearRegFilterKey || groupKey === 'locationId'
        ? apiFacet.key
        : formatPrice(Number(apiFacet.key));
};
