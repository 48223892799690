import { hesselViewModels } from '../../lib/view-models';
import { MarketingCardAvailabilityType } from '../../lib/view-models/vehicle';

function mapUmbracoAvailabilityToVehicleAvailability(
    availabilityList: Array<MarketingCardAvailabilityType>
): Array<hesselViewModels.VehicleAvailability> {
    return availabilityList.map((availability) => {
        switch (availability) {
            case 'Bestilling':
                return 'Order';
            case 'Lager':
                return 'InStock';
            case 'Brugt':
                return 'Used';
            case 'Engros':
                return 'Engros';
            case 'Demo':
                return 'Demo';
            default: {
                const check: never = availability;
                return check;
            }
        }
    });
}

export function filterMarketingCardOnAvailability(
    selectedAvailability: hesselViewModels.VehicleAvailability,
    cardAvailabilities?: MarketingCardAvailabilityType[]
): boolean {
    if (!cardAvailabilities?.length) {
        return false;
    }

    const availabilityList = mapUmbracoAvailabilityToVehicleAvailability(cardAvailabilities);

    return availabilityList.includes(selectedAvailability);
}
